









import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class QaiIndicator extends Vue {
  @Prop() public value!: number;
  @Prop({default: '40px'}) public size!: string;
  get cssValue() {
    if (this.value === 0) return 'text-ccok'
    if (this.value === 1) return 'text-ccwarn'
    if (this.value === 2) return 'text-ccko'
    return 'text-ccoffline'
  }
}
