import date from 'quasar/src/utils/date.js';;


function getWeekStart(dt: Date) {
    dt = date.subtractFromDate(dt, { days: date.getDayOfWeek(dt) - 1 });
    return date.adjustDate(dt, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
}


function getDateFromWeek(year: number, week: number) {
  // sample taken from https://stackoverflow.com/questions/16590500/javascript-calculate-date-from-week-number
  const simple = new Date(year, 0, 1 + (week - 1) * 7);
  const dow = simple.getDay();
  const dt = simple;
  if (dow <= 4) {
      dt.setDate(simple.getDate() - simple.getDay() + 1);
  } else {
      dt.setDate(simple.getDate() + 8 - simple.getDay());
  }
  return dt;
}

function getMonthStart(dt: Date) {
    dt = date.subtractFromDate(dt, { days: dt.getDay() });
    return date.adjustDate(dt, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
}

function getDateFromMonth(year: number, month: number) {
    return new Date(year, month - 1, 1);
}

export {
    getDateFromWeek,
    getWeekStart,
    getMonthStart,
    getDateFromMonth,
};
