






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ReportingCardInfo extends Vue {
    @Prop({default: 0}) public readonly value!: number;
    @Prop({default: 'QAI'}) public readonly title!: string;
    @Prop() public readonly icon!: string | undefined;
    @Prop() public readonly iconGood!: string | undefined;
    @Prop() public readonly iconBad!: string | undefined;
    @Prop({default: '60px'}) public readonly size!: string;
    @Prop() public readonly tooltip!: string;
}
