import { render, staticRenderFns } from "./ReportingCardInfo.vue?vue&type=template&id=25a938ea&scoped=true&"
import script from "./ReportingCardInfo.vue?vue&type=script&lang=ts&"
export * from "./ReportingCardInfo.vue?vue&type=script&lang=ts&"
import style0 from "./ReportingCardInfo.vue?vue&type=style&index=0&id=25a938ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a938ea",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIcon});
