import { render, staticRenderFns } from "./ManageBuildingReporting.vue?vue&type=template&id=67627952&scoped=true&"
import script from "./ManageBuildingReporting.vue?vue&type=script&lang=ts&"
export * from "./ManageBuildingReporting.vue?vue&type=script&lang=ts&"
import style0 from "./ManageBuildingReporting.vue?vue&type=style&index=0&id=67627952&lang=scss&scoped=true&"
import style1 from "./ManageBuildingReporting.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67627952",
  null
  
)

/* custom blocks */
import block0 from "./ManageBuildingReporting.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QSpinnerTail from 'quasar/src/components/spinner/QSpinnerTail.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QOptionGroup,QIcon,QBtn,QPopupProxy,QBanner,QSpinnerDots,QSpinnerTail});
