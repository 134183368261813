





















































































































































import { Route } from "vue-router";
import { Component, Prop, Vue, Watch, Model } from "vue-property-decorator";
import { apiGet } from "@/store";
import date from 'quasar/src/utils/date.js';;
import { getDateFromWeek, getWeekStart, getDateFromMonth, getMonthStart } from "../date";
import Reporting from "@/components/Reporting.vue";
import moment from "moment";
import format from 'quasar/src/utils/format.js';;
import debounce from "lodash/debounce";
import { FunctionalCalendar } from "vue-functional-calendar";
import i18n from "@/i18n";
import MonthPicker from "@/components/MonthPicker.vue";
import QaiIndicator from "@/components/QaiIndicator.vue";
import CCDropDown from "@/components/CCDropDown.vue";
import { saveAs } from "file-saver";

@Component({
  components: {
    FunctionalCalendar,
    Reporting,
    MonthPicker,
    QaiIndicator,
    CCDropDown,
  },
  beforeRouteEnter(to: Route, from: Route, next: any) {
    const dCurrent = getWeekStart(date.subtractFromDate(new Date(), { days: 8 }));
    if (to.params.year === undefined || to.params.period === undefined || to.params.sub === undefined) {
      // auto move to last week
      const week = "" + date.getWeekOfYear(dCurrent);
      const year = "" + dCurrent.getFullYear();
      const params = to.params;
      params.year = year;
      params.period = "week";
      params.sub = week;
      next({
        name: "manage-building-reporting-by-period",
        params,
      });
    } else if (to.params.period === "week") {
      // ensure not going further than now
      const dParams = getDateFromWeek(parseInt(to.params.year, 10), parseInt(to.params.sub, 10));
      const diff = date.getDateDiff(dParams, dCurrent, "days");
      if (diff > 0) {
        const params = to.params;
        delete params.year;
        delete params.period;
        delete params.sub;
        next({
          name: "manage-building-reporting",
          params,
        });
      } else {
        next();
      }
    } else if (to.params.period === "month") {
      // ensure not going further than now
      const dCurrentMonth = getMonthStart(date.addToDate(date.subtractFromDate(new Date(), { month: 1 }), { days: 1 }));
      const dParams = getDateFromMonth(parseInt(to.params.year, 10), parseInt(to.params.sub, 10));
      const diff = date.getDateDiff(dParams, dCurrentMonth, "days");
      if (diff >= 0) {
        const params = to.params;
        delete params.year;
        delete params.period;
        delete params.sub;
        next({
          name: "manage-building-reporting",
          params,
        });
      } else {
        next();
      }
    }
  },
})
export default class ManageBuildingReporting extends Vue {
  @Prop() public id!: number;
  @Prop() public building!: any;
  @Prop() public year!: number;
  @Prop() public period!: string;
  @Prop() public sub!: number;
  @Prop() public modelKey!: string;
  @Prop() public areaId!: number;

  public $store: any;
  public $q: any;
  public report: any = null;
  public reportBuilding: any = null;
  public reportPeriodStart: any = null;
  public reportPeriodEnd: any = null;
  public reportParams: Map<string, string> = new Map<string, string>();
  public loading: boolean = false;
  private debounceLoadReporting: any = null;
  public reportStatus: any = null;
  public reportError: any = null;
  public selectorDateStart: any = "2020-01-01";
  public selectorDateEnd: any = "2020-01-06";
  public selectorRanges: any = [];
  public selectorKey: number = 0;
  public selectorCurrentDate: any = new Date();
  public calendarData: any = {};
  public selectorWeekdays: any = moment.weekdaysShort(true);
  public selectorMonths: any = moment.months();
  public selectorLimits: any = false;
  public selectorLimitsMonths: any = false;
  private limits: any = false;
  public selectorOpened: boolean = false;
  public params: any = null;
  public paramsBuilding: any = null;
  public pdfDownloading: boolean = false;
  private reportingRefresh: any = null;
  public showAllAreas: boolean = false;
  public preventRefresh: boolean = false;
  public lastAreaName: string = "";

  get periodOptions() {
    return [
      { label: i18n.t("period_week"), value: "week" },
      { label: i18n.t("period_month"), value: "month" },
    ];
  }

  get periodLabel() {
    return this.periodOptions.find((o) => o.value === this.period)?.label;
  }

  get isGenerationCompleted() {
    return this.reportStatus === "ready";
  }

  get area() {
    if (!this.areaId) {
      return null;
    }
    return this.$store.getters.getAreaFromId(this.areaId);
  }

  public created() {
    this.debounceLoadReporting = debounce(this.loadReporting, 200);
  }

  public mounted() {
    this.refresh();
  }

  get routerKey() {
    return [this.building?.id, this.areaId, this.modelKey, this.year, this.period, this.sub];
  }

  get currentName() {
    if (!this.params) {
      return "";
    }
    if (this.params.model_key === "building") {
      return this.building?.name;
    } else if (this.params.model_key === "area") {
      return this.area?.name || this.lastAreaName;
    }
  }

  get currentReportName() {
    if (!this.params) {
      return "";
    }
    if (this.params.model_key === "building") {
      return "Bâtiment " + this.building?.name;
    } else if (this.params.model_key === "area") {
      return "Pièce " + (this.area?.name || this.lastAreaName);
    }
  }

  public updateRouteFromParams() {
    this.preventRefresh = true;
    if (this.params.model_key === "building") {
      this.$router.replace({
        name: `manage-building-reporting-by-period`,
        params: {
          id: this.building.id,
          year: this.params.year,
          period: this.params.period,
          sub: this.params[this.params.period],
        },
      });
    } else if (this.params.model_key === "area") {
      this.$router.replace({
        name: `manage-area-reporting-by-period`,
        params: {
          id: this.building.id,
          areaId: this.params.model_id,
          year: this.params.year,
          period: this.params.period,
          sub: this.params[this.params.period],
        },
      });
    }
    this.preventRefresh = false;
  }

  public loadBuildingReporting() {
    this.report = this.reportBuilding;
    this.params = this.paramsBuilding;
    this.updateRouteFromParams();
  }

  public loadAreaReporting(areaId: number, areaName: string) {
    this.lastAreaName = areaName;
    const params = {
      model_key: "area",
      period: this.period,
      year: this.year,
      model_id: areaId,
    };
    if (this.period === "week") {
      params["week"] = this.sub;
    } else if (this.period === "month") {
      params["month"] = this.sub;
    }
    this.params = params;
    this.report = null;
    this.loadReportingFromParams({ direct: true, updateRoute: true });
  }

  @Watch("routerKey")
  private refresh() {
    if (this.preventRefresh) return;
    if (this.building.id === undefined) {
      this.loading = false;
      this.report = null;
      return;
    }
    const params = {
      model_id: 0,
      model_key: this.modelKey,
      period: this.period,
      year: this.year,
    };
    if (this.modelKey === "building") {
      params.model_id = this.building.id;
    } else if (this.modelKey === "area") {
      params.model_id = this.areaId;
    }

    this.loading = true;
    this.report = null;

    if (this.period === "week") {
      params["week"] = this.sub;
      this.updateDateFromWeek(this.year, this.sub);
    } else if (this.period === "month") {
      params["month"] = this.sub;
      this.updateDateFromMonth(this.year, this.sub);
    }
    this.params = params;

    // check if we need to show an error message
    let canGo = true;
    if (this.period === "week") {
      canGo = this.canGoWeek(this.year, this.sub);
    } else if (this.period === "month") {
      canGo = this.canGoMonth(this.year, this.sub);
    }

    if (!canGo) {
      this.loading = false;
      this.reportStatus = "error";
      this.reportError = this.$t(`first_time_${this.period}`);
      return;
    }

    if (params.model_key !== "building" && this.reportBuilding === null) {
      this.loadBuildingReportingOrRedirect(this.params);
    }

    this.loadReportingFromParams({ updateRoute: false });
  }

  public toggleShowAllAreas() {
    this.showAllAreas = !this.showAllAreas;
  }

  get haveMoreAreas() {
    if (this.reportBuilding === null) return false;
    return this.reportBuilding.content.areas.length > 4;
  }

  public getAreaRanking(areaId: number) {
    const ranking = this.reportBuilding.content.areas_ranking.find(
      (entry: { area_id: number }) => entry.area_id === areaId
    );
    if (ranking) return ranking.rank;
    return null;
  }

  public getBuildingIcon(building: any) {
    return this.$store.getters.getBuildingIcon(building);
  }

  public getAreaQai(areaId: number) {
    const ranking = this.reportBuilding.content.areas_ranking.find(
      (entry: { area_id: number }) => entry.area_id === areaId
    );
    if (ranking) return ranking.color;
    return null;
  }

  get areasOrderedByRanking() {
    if (this.reportBuilding === null) return [];
    const report = this.reportBuilding.content;
    const availableAreas = report.areas_ranking.map((entry: { area_id: any }) => entry.area_id);
    const sortedAreas = Object.entries(report.areas).filter((area) => availableAreas.includes(parseInt(area[0])));
    sortedAreas.sort((a, b) => {
      const rank1 = this.getAreaRanking(parseInt(a[0]));
      const rank2 = this.getAreaRanking(parseInt(b[0]));
      const name1: string = <string>a[1];
      const name2: string = <string>b[1];
      if (rank1 !== null && rank2 !== null) {
        return rank2 - rank1;
      } else if (rank1 === null && rank2 === null) {
        return name2.localeCompare(name1);
      } else if (rank1 === null) {
        return 1;
      } else if (rank1 === null) {
        return -1;
      }
      return 0;
    });
    return sortedAreas;
  }

  get cardCss() {
    if (this.$q.screen.lt.sm && this.$q.screen.width > 300) {
      return "col-6";
    }
    if (this.$q.screen.width < 800) {
      return "col-6";
    } else if (this.$q.screen.md) {
      return "col-3";
    } else if (this.$q.screen.lg) {
      return "col-3";
    } else if (this.$q.screen.xl) {
      return "col-3";
    }
    return "col-6";
  }
  private reload() {
    this.loadReportingFromParams({ updateRoute: false });
    this.loading = false;
  }

  private loadReportingFromParams(options: any = {}) {
    if (this.reportingRefresh !== null) {
      clearTimeout(this.reportingRefresh);
      this.reportingRefresh = null;
    }
    if (options.updateRoute) {
      this.updateRouteFromParams();
    }
    if (options.direct) {
      this.loadReporting(this.params, this.routerKey);
    } else {
      this.debounceLoadReporting(this.params, this.routerKey);
    }
  }

  private loadBuildingReportingOrRedirect(areaParams: Map<string, string>) {
    if (this.reportBuilding !== null) {
      return;
    }
    const params = {
      model_key: "building",
      model_id: this.building.id,
      period: areaParams["period"],
      year: areaParams["year"],
      month: areaParams["month"],
      week: areaParams["week"],
    };

    return apiGet("/reporting", params).then((response: any): void => {
      if (response.status === "ready" || response.status === "created" || response.status === "processing") {
        if (response.content !== null) {
          this.reportBuilding = response;
          this.paramsBuilding = params;
          return;
        }
      }

      // whatever happen then, return to building report instead.
      const routeParams = {
        model_key: "building",
        model_id: this.building.id,
        period: areaParams["period"],
        year: areaParams["year"],
        sub: areaParams[areaParams["period"]],
      };
      this.$router.push({
        name: "manage-building-reporting-by-period",
        params: routeParams,
      });
    });
  }

  private loadReporting(params: Map<string, string>, routerKey: number[]) {
    // this implementation prevent loading a reporting when navigating
    // however, we don't want to show reporting of request loading, but
    // the navigation already changed
    return apiGet("/reporting", params)
      .then((response: any) => {
        if (routerKey !== this.routerKey) {
          return;
        }
        this.report = null;
        this.reportStatus = response.status;
        this.reportError = null;
        if (response.status === "ready") {
          this.report = response;
          this.reportPeriodStart = response.dt_from;
          this.reportPeriodEnd = response.dt_to;
          if (this.report.model_key === "building") {
            this.reportBuilding = this.report;
            this.paramsBuilding = params;
          }
        } else if (response.status === "error") {
          if ((response.error || "").indexOf("Not enough data")) {
            this.reportError = i18n.t("REPORTING_ERROR_NO_ENOUGH_DATA");
          } else {
            this.reportError = i18n.t("REPORTING_ERROR");
          }
          this.reportPeriodStart = response.dt_from;
          this.reportPeriodEnd = response.dt_to;
        } else if (response.status === "created" || response.status === "processing") {
          if (this.reportingRefresh !== null) {
            clearTimeout(this.reportingRefresh);
            this.reportingRefresh = null;
          }
          if (response.content !== null) {
            this.report = response;
            this.reportPeriodStart = response.dt_from;
            this.reportPeriodEnd = response.dt_to;
            if (this.report.model_key === "building") {
              this.reportBuilding = this.report;
              this.paramsBuilding = params;
            }
            this.loading = false;
          }
          this.reportingRefresh = setTimeout(this.reload, 1000);
        }
        this.reportParams = params;
        this.loading = false;
      })
      .catch((error: any) => {
        if (routerKey !== this.routerKey) {
          return;
        }
        this.globalError(error);
        this.loading = false;
      });
  }

  private updateDateFromWeek(year: number, week: number) {
    const dtStart = getDateFromWeek(year, week);
    const dtEnd = date.addToDate(dtStart, { days: 7 });
    this.reportPeriodStart = date.formatDate(dtStart, "YYYY-MM-DDTHH:mm:ss") + "Z";
    this.reportPeriodEnd = date.formatDate(dtEnd, "YYYY-MM-DDTHH:mm:ss") + "Z";
    this.setSelectorRanges(dtStart);
  }

  private updateDateFromMonth(year: number, month: number) {
    const dtStart = getDateFromMonth(year, month);
    const dtEnd = date.addToDate(dtStart, { month: 1 });
    this.reportPeriodStart = date.formatDate(dtStart, "YYYY-MM-DDTHH:mm:ss") + "Z";
    this.reportPeriodEnd = date.formatDate(dtEnd, "YYYY-MM-DDTHH:mm:ss") + "Z";
    this.setSelectorRanges(dtStart);
  }

  get reportPeriodText() {
    if (this.reportPeriodStart === null && this.reportPeriodEnd === null) {
      if (this.period === "week") {
        this.updateDateFromWeek(this.year, this.sub);
      } else {
        this.updateDateFromMonth(this.year, this.sub);
      }
    }

    const dtext1 = this.reportPeriodStart.slice(0, -1);
    const dtext2 = this.reportPeriodEnd.slice(0, -1);
    let months = moment.months();
    const d1 = date.extractDate(dtext1, "YYYY-MM-DDTHH:mm:ss");
    let d2 = date.extractDate(dtext2, "YYYY-MM-DDTHH:mm:ss");
    d2 = date.subtractFromDate(d2, { seconds: 1 });

    if (this.$q.screen.xs) {
      months = moment.monthsShort();
    }

    // day1 month1 year1 - day2 month2 year2
    // day1-day2 month2 year2
    // day1 month1 - day2 month2 year2
    const day1 = d1.getDate();
    const month1 = d1.getMonth();
    const year1 = d1.getFullYear();
    const day2 = d2.getDate();
    const month2 = d2.getMonth();
    const year2 = d2.getFullYear();
    const textMonth1 = format.capitalize(months[month1]);
    const textMonth2 = format.capitalize(months[month2]);

    if (this.period === "month") {
      return `${textMonth1} ${year1}`;
    } else {
      if (year1 !== year2) {
        return `${day1} ${textMonth1} ${year1} - ${day2} ${textMonth2} ${year2}`;
      } else if (month1 !== month2) {
        return `${day1} ${textMonth1} - ${day2} ${textMonth2} ${year2}`;
      } else {
        return `${day1} - ${day2} ${textMonth2} ${year2}`;
      }
    }
  }

  public prevPeriod() {
    let params = {};
    if (this.period === "week") {
      let d = getDateFromWeek(this.year, this.sub);
      d = date.subtractFromDate(d, { days: 7 });
      params = {
        year: "" + d.getFullYear(),
        period: "week",
        sub: "" + date.getWeekOfYear(d),
        id: "" + this.building.id,
      };
    } else {
      let d = getDateFromMonth(this.year, this.sub);
      d = date.subtractFromDate(d, { month: 1 });
      params = {
        year: "" + d.getFullYear(),
        period: "month",
        sub: "" + (d.getMonth() + 1),
        id: "" + this.building.id,
      };
    }
    this.$router.push({
      name: "manage-building-reporting-by-period",
      params,
    });
  }

  public nextPeriod() {
    let params = {};
    if (this.period === "week") {
      let d = getDateFromWeek(this.year, this.sub);
      d = date.addToDate(d, { days: 7 });
      params = {
        year: "" + d.getFullYear(),
        period: "week",
        sub: "" + date.getWeekOfYear(d),
        id: "" + this.building.id,
      };
    } else {
      let d = getDateFromMonth(this.year, this.sub);
      d = date.addToDate(d, { month: 1 });
      params = {
        year: "" + d.getFullYear(),
        period: "month",
        sub: "" + (d.getMonth() + 1),
        id: "" + this.building.id,
      };
    }
    this.$router.push({
      name: "manage-building-reporting-by-period",
      params,
    });
  }

  private canGoWeek(year: number, week: number) {
    const rangeDiff = date.getDateDiff(this.limits.max, this.limits.min, "days");
    const dt = getDateFromWeek(year, week);
    const diffMin = date.getDateDiff(dt, this.limits.min, "days");
    const diffMax = date.getDateDiff(dt, this.limits.max, "days");
    return rangeDiff > 0 && diffMin >= 0 && diffMax <= 0;
  }

  private canGoMonth(year: number, month: number) {
    const rangeDiff = date.getDateDiff(this.selectorLimitsMonths.max, this.selectorLimitsMonths.min, "days");
    const dt = getDateFromMonth(year, month);
    const diffMin = date.getDateDiff(dt, this.selectorLimitsMonths.min, "days");
    const diffMax = date.getDateDiff(dt, this.selectorLimitsMonths.max, "days");
    return rangeDiff > 0 && diffMin >= 0 && diffMax <= 0;
  }

  get canGoPrevPeriod() {
    if (this.period === "week") {
      return this.canGoWeek(this.year, this.sub - 1);
    } else {
      return this.canGoMonth(this.year, this.sub - 1);
    }
  }

  get canGoNextPeriod() {
    if (this.period === "week") {
      return this.canGoWeek(this.year, this.sub + 1);
    } else {
      return this.canGoMonth(this.year, this.sub + 1);
    }
  }

  public selectWeek(obj: any) {
    const d = date.extractDate(obj.date, "D/M/YYYY");
    const year = d.getFullYear();
    const week = date.getWeekOfYear(d);
    if (!this.canGoWeek(year, week)) {
      return;
    }
    const params = {
      year: "" + year,
      period: "week",
      sub: "" + week,
      id: "" + this.building.id,
    };
    this.$router.push({
      name: "manage-building-reporting-by-period",
      params,
    });
  }

  public selectMonth(dt: any) {
    const params = {
      year: "" + dt.getFullYear(),
      period: "month",
      sub: "" + (dt.getMonth() + 1),
      id: "" + this.building.id,
    };
    this.$router.push({
      name: "manage-building-reporting-by-period",
      params,
    });
  }

  private setSelectorRanges(d: any) {
    const dayOfWeek = date.getDayOfWeek(d);
    const days = 1 - dayOfWeek;
    const start = date.addToDate(d, { days });
    const end = date.addToDate(d, { days: 6 + days });
    this.selectorDateStart = date.formatDate(start, "D/M/YYYY");
    this.selectorDateEnd = date.formatDate(end, "D/M/YYYY");
    this.selectorRanges = [
      {
        start: this.selectorDateStart,
        end: this.selectorDateEnd,
      },
    ];
    this.selectorKey += 1;

    const now = date.subtractFromDate(new Date(), { days: 8 });
    const cWeek = date.getWeekOfYear(now);
    const cYear = now.getFullYear();

    const buildingCreatedAtText = this.building.created_at;
    const buildingCreatedAt = this.getStart(
      date.extractDate(buildingCreatedAtText.slice(0, -1), "YYYY-MM-DDTHH:mm:ss")
    );
    this.limits = {
      min: buildingCreatedAt,
      max: getDateFromWeek(cYear, cWeek),
    };
    this.selectorLimits = {
      min: date.formatDate(buildingCreatedAt, "D/M/YYYY"),
      max: date.formatDate(getDateFromWeek(cYear, cWeek), "D/M/YYYY"),
    };
    this.selectorLimitsMonths = {
      min: buildingCreatedAt,
      max: date.subtractFromDate(new Date(), { month: 1 }),
    };
    this.selectorCurrentDate = d;
  }

  private getStart(dt: any) {
    if (this.period === "week") {
      return getWeekStart(dt);
    } else if (this.period === "month") {
      return getMonthStart(dt);
    }
  }

  public updatePeriod(period: any) {
    let params = {};
    if (period === "week") {
      // month to week
      // ensure the week is not after or equal to the current week
      let dt = date.addToDate(getWeekStart(getDateFromMonth(this.year, this.sub)), { days: 6 });
      const diff = date.getDateDiff(dt, this.selectorLimitsMonths.max, "days");
      if (diff >= 0) {
        dt = this.selectorLimits.max;
      }
      params = {
        id: "" + this.building.id,
        sub: "" + date.getWeekOfYear(dt),
        year: "" + dt.getFullYear(),
        period,
      };
    } else if (period === "month") {
      // week to month
      // ensure the month is not the current month of the current week
      let dt = getMonthStart(date.addToDate(getDateFromWeek(this.year, this.sub), { days: 6 }));
      const diff = date.getDateDiff(dt, this.selectorLimitsMonths.max, "days");
      if (diff >= 0) {
        dt = this.selectorLimitsMonths.max;
      }

      params = {
        id: "" + this.building.id,
        sub: "" + (dt.getMonth() + 1),
        year: "" + dt.getFullYear(),
        period,
      };
    }
    this.$router.push({
      name: "manage-building-reporting-by-period",
      params,
    });
  }

  public downloadPdf() {
    const url = `${this.$route.fullPath}/export/pdf`;
    const params = { ...this.reportParams };
    params["media"] = "pdf";
    this.pdfDownloading = true;
    apiGet("/reporting", params, false, {
      responseType: "blob",
      useRaw: true,
    })
      .then((axiosResponse: any) => {
        const filename = axiosResponse.headers["x-filename"];
        saveAs(axiosResponse.data, filename);
      })
      .finally(() => {
        this.pdfDownloading = false;
      });
  }
}
