









































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ReportingCardInfo from '../components/ReportingCardInfo.vue';
import QaiIndicator from '../components/QaiIndicator.vue';
import moment from 'moment';
import format from 'quasar/src/utils/format.js';;
import date from 'quasar/src/utils/date.js';;

@Component({
  components: {
    ReportingCardInfo,
    QaiIndicator,
  },
})
export default class Reporting extends Vue {
  @Prop() public report: any;
  @Prop() public organisation: any;
  @Prop() public model: any;
  @Prop() public modelKey: any;
  @Prop() public metadata: any;
  @Prop({ default: 'web' }) public media!: string;
  private timeOfAlertTrendCss: any = null;
  private tooltipShow: boolean = false;
  private tooltipHideTid: any = null;
  private tooltipPositionned: number = 0;
  private tooltipX: number = 0;
  private tooltipY: number = 0;
  private tooltipText: any = '';

  public mounted() {
    if (this.report !== undefined) {
      this.attachSvgHook();
    }
  }

  get tooltipStyle() {
    return {
      left: `${this.tooltipX}px`,
      top: `${this.tooltipY}px`,
      display: this.tooltipShow ? 'block' : 'none',
    };
  }

  public dateUp(date: any) {
    return moment(date).format('DD');
  }

  public dateDown(date: any) {
    return moment(date).format('ddd');
  }

  @Watch('report')
  private attachSvgHook() {
    document.body.querySelectorAll('*[data-tooltip-type]').forEach((element: any) => {
      element.addEventListener('mouseover', () => {
        // format text depending on the svg
        const dataType = element.getAttribute('data-tooltip-type');
        const dataValue = element.getAttribute('data-tooltip-value');
        if (dataType === 'qai') {
          const dataValueInt = parseInt(dataValue, 10);
          if (dataValueInt === 0) {
            this.tooltipText = this.$t('QAI_GOOD');
          } else if (dataValueInt === 1) {
            this.tooltipText = this.$t('QAI_WARNING');
          } else if (dataValueInt === 2) {
            this.tooltipText = this.$t('QAI_CRITICAL');
          } else {
            this.hideTooltip();
            return;
          }
        } else if (dataType === 'text') {
          this.tooltipText = dataValue;
        } else {
          this.hideTooltip();
          return;
        }

        this.showTooltip();
        this.$nextTick(() => {
          const tel: any = document.getElementById('svg-tooltip');
          const bounds = tel.getBoundingClientRect();
          const elBounds = element.getBoundingClientRect();
          this.tooltipX = elBounds.x - (bounds.x - tel.offsetLeft) - bounds.width / 2 + elBounds.width / 2;
          this.tooltipY = elBounds.y - (bounds.y - tel.offsetTop) - (20 + bounds.height);
          this.tooltipPositionned += 1;
        });
      });
      element.addEventListener('mouseout', () => {
        this.hideTooltip();
      });
    });
  }

  private showTooltip() {
    this.tooltipShow = true;
    if (this.tooltipHideTid !== null) {
      clearTimeout(this.tooltipHideTid);
      this.tooltipHideTid = null;
    }
  }

  private hideTooltip() {
    if (this.tooltipHideTid !== null) {
      return;
    }
    this.tooltipHideTid = setTimeout(() => {
      this.tooltipHideTid = null;
      this.tooltipShow = false;
      this.tooltipPositionned = 0;
    }, 500);
  }

  private pollutantTr(pollutant: any) {
    return this.$t('TEXT_PRONOUN_' + pollutant.key.toUpperCase());
  }

  @Watch('report')
  private updateCss() {
    if (this.report === null || this.report.time_of_alert_trend === null) {
      this.timeOfAlertTrendCss = null;
    } else if (this.report.time_of_alert_trend < 0) {
      this.timeOfAlertTrendCss = 'text-ok';
    } else if (this.report.time_of_alert_trend > 0) {
      this.timeOfAlertTrendCss = 'text-ko';
    }
  }

  public indiceToText(value: number) {
    if (value === 1) {
      return this.$t('QAI_WARNING');
    } else if (value === 2) {
      return this.$t('QAI_CRITICAL');
    }
    return this.$t('QAI_GOOD');
  }

  public indiceToColor(value: number) {
    if (value === null) {
      return 'ccoffline';
    } else if (value === 1) {
      return 'ccwarn';
    } else if (value === 2) {
      return 'ccko';
    }
    return 'ccok';
  }

  public localizeTemplateDays(value: string) {
    const days = moment.weekdays();
    return value
      .replace(/#DAY1#/, days[1][0].toUpperCase() + days[1].substring(1))
      .replace(/#DAY2#/, days[2][0].toUpperCase() + days[2].substring(1))
      .replace(/#DAY3#/, days[3][0].toUpperCase() + days[3].substring(1))
      .replace(/#DAY4#/, days[4][0].toUpperCase() + days[4].substring(1))
      .replace(/#DAY5#/, days[5][0].toUpperCase() + days[5].substring(1))
      .replace(/#DAY6#/, days[6][0].toUpperCase() + days[6].substring(1))
      .replace(/#DAY7#/, days[0][0].toUpperCase() + days[0].substring(1));
  }

  get reportPeriodText() {
    const metadata = this.metadata;
    const dtext1 = metadata.dt_from.slice(0, -1);
    const dtext2 = metadata.dt_to.slice(0, -1);
    let months = moment.months();
    const d1 = date.extractDate(dtext1, 'YYYY-MM-DDTHH:mm:ss');
    let d2 = date.extractDate(dtext2, 'YYYY-MM-DDTHH:mm:ss');
    d2 = date.subtractFromDate(d2, { seconds: 1 });

    if (this.$q.screen.xs) {
      months = moment.monthsShort();
    }

    // day1 month1 year1 - day2 month2 year2
    // day1-day2 month2 year2
    // day1 month1 - day2 month2 year2
    const day1 = d1.getDate();
    const month1 = d1.getMonth();
    const year1 = d1.getFullYear();
    const day2 = d2.getDate();
    const month2 = d2.getMonth();
    const year2 = d2.getFullYear();
    const textMonth1 = format.capitalize(months[month1]);
    const textMonth2 = format.capitalize(months[month2]);

    if (metadata.period === 'month') {
      return `${textMonth1} ${year1}`;
    } else {
      if (year1 !== year2) {
        return `${day1} ${textMonth1} ${year1} - ${day2} ${textMonth2} ${year2}`;
      } else if (month1 !== month2) {
        return `${day1} ${textMonth1} - ${day2} ${textMonth2} ${year2}`;
      } else {
        return `${day1} - ${day2} ${textMonth2} ${year2}`;
      }
    }
  }

  private getPollutantText(key: string, docKey?: string) {
    docKey = docKey || key;
    if (this.report[key].value <= 0) {
      return null;
    }
    if (this.report[key].polls.length > 1) {
      return this.$t(
        `${docKey}_info_2`, {
        pollutant1: this.pollutantTr(this.report[key].polls[0]),
        pollutant2: this.pollutantTr(this.report[key].polls[1])
      });
    }
    return this.$t(
      `${docKey}_info_1`, {
      pollutant1: this.pollutantTr(this.report[key].polls[0])
    });
  }

  get reportQaiPollsText() {
    if (this.modelKey === 'building') {
      return this.getPollutantText('building_qai');
    } else if (this.modelKey === 'area') {
      return this.getPollutantText('area_qai');
    }
  }

  get reportHealthPollsText() {
    if (this.modelKey === 'building') {
      return this.getPollutantText('building_health');
    } else if (this.modelKey === 'area') {
      return this.getPollutantText('building_health', 'area_health');
    }
  }

  get reportOccupantsHealthPollsText() {
    return this.getPollutantText('occupants_health');
  }

  get reportOccupantsComfortPollsText() {
    return this.getPollutantText('occupants_comfort');
  }

  get reportQaiValue() {
    if (this.modelKey === 'building') {
      return this.report.building_qai.value;
    } else if (this.modelKey === 'area') {
      return this.report.area_qai.value;
    }
  }

}
