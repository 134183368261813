
<template>
  <div class="monthpicker">
    <div class="monthpicker-header">
      <q-btn
        @click="changeYear(false)"
        dense
        :disable="minClean && minClean.getFullYear() === selectedYear.getFullYear()"
        flat
        icon="navigate_before"
        round
      />
      {{ selectedYear.getFullYear() }}
      <q-btn
        @click="changeYear(true)"
        dense
        :disable="maxClean && maxClean.getFullYear() === selectedYear.getFullYear()"
        flat
        icon="navigate_next"
        round
      />
    </div>
    <div class="monthpicker-months">
      <q-btn
        :class="{ 'monthpicker-current': isCurrentMonth(month) }"
        @click="selectMonth(month)"
        :color="isSelectedMonth(month) ? color : ''"
        :disable="isDisabled(month)"
        :outline="isSelectedMonth(month)"
        :flat="!isSelectedMonth(month)"
        :key="month.getTime()"
        :label="month.toLocaleDateString(localeArray, {
          month: 'short',
        })"
        no-caps
        no-ripple
        rounded
        :text-color="isCurrentMonth(month) && !isSelectedMonth(month) ? color : ''"
        v-for="month in displayedMonths"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MonthPicker',
  computed: {
    displayedMonths() {
      const months = [];
      for (let i = 0; i < 12; i++) {
        months.push(new Date(this.selectedYear.getFullYear(), i));
      }
      return months;
    },
    localeArray() {
      return this.locale ? [this.locale] : [];
    },
    maxClean() {
      return this.max ? this.cleanDate(this.max) : null;
    },
    minClean() {
      return this.min ? this.cleanDate(this.min) : null;
    },
  },
  created() {
    // reset the current month frequently in case it changes
    this.interval = setInterval(() => {
      this.currentMonth = this.cleanDate();
    }, 10000);
  },
  data() {
    return {
      currentMonth: this.cleanDate(),
      interval: null,
      // initial value
      selectedYear: null,
      selectedMonth: null,
    };
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    changeYear(up) {
      if (up) {
        let d = new Date(this.selectedYear);
        d.setMonth(d.getMonth() + 12);
        if (this.maxClean && d > this.maxClean) {
          d = this.maxClean;
        }
        // this.selectMonth(d);
        this.selectedYear = d;
      } else {
        let d = new Date(this.selectedYear);
        d.setMonth(d.getMonth() - 12);
        if (this.minClean && d < this.minClean) {
          d = this.minClean;
        }
        // this.selectMonth(d);
        this.selectedYear = d;
      }
    },
    cleanDate(date) {
      const d = date ? new Date(date) : new Date();
      d.setDate(1);
      d.setHours(0, 0, 0, 0);
      return d;
    },
    isCurrentMonth(month) {
      return this.currentMonth.getTime() === month.getTime();
    },
    isDisabled(month) {
      let disabled = false;
      if (  (this.minClean && month < this.minClean)
        ||  (this.maxClean && month > this.maxClean)
      ) {
        disabled = true;
      }
      return disabled;
    },
    isSelectedMonth(month) {
      return this.selectedMonth ?
        (this.selectedMonth.getTime() === month.getTime()) :
        false;
    },
    selectMonth(month) {
      this.$emit('input', month);
    },
  },
  props: [
    'color',
    'locale',
    'max',
    'min',
    'value',
  ],
  watch: {
    value: {
      handler(val) {
        this.selectedYear =  val ?
          this.cleanDate(val) :
          this.currentMonth;
        this.selectedMonth =  val ?
          this.cleanDate(val) :
          this.currentMonth;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.monthpicker {
  margin: 12px auto;
  width: 250px;
}

.monthpicker-current {
  font-weight: bold;
}

.monthpicker-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 500;
}

.monthpicker-months {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-wrap: wrap;
  button {
    font-weight: normal;
    font-size: 14px;
    &.disabled * {
      color: #bfbfbf;
    }
  }
}

.monthpicker-months .q-btn {
  box-shadow: none;
  margin: 4px 0;
  width: 30%;
}
</style>